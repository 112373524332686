/* eslint-disable react/jsx-curly-newline */
/* eslint-disable jsx-a11y/label-has-associated-control */
// eslint-disable-next-line
import { useEffect, useState, useContext } from "react";
import { css, jsx } from "@emotion/react";
import { useQuery } from "@apollo/client";
import { SplitFactory, SplitTreatments } from "@splitsoftware/splitio-react";
import { Flex, Link, theme, Toast, Text } from "@xometry/xometry_loft";
import * as Sentry from "@sentry/react";
import { FullStory } from "@fullstory/browser";
import Auth from "@xometry/auth-context";
import { prismicClient } from "../../apollo";
/** @jsx jsx */
import { MarketingCards } from "../MarketingCards";
import PersonalizedMarketingCards from "../MarketingCards/PersonalizedMarketingCards";
import { QuoteWizardWithRequestQuote } from "../QuoteWizard";
import {
  topWrapper,
  errorWrapper,
  leftColumn,
  mainGrid,
  rightColumn,
  welcomeTextWrapper,
  subtitle,
  adsContainer,
  TabContainer,
  tableContainer,
  switcherNav,
  dashboardTitle,
} from "./App.style";
import { lightPanel } from "../../index.style";
import {
  GetLoggedInUserQuery,
  GetLoggedInUserQueryResult,
  GetUserCarbonOffsetQuery,
  GetUsersOrganizationsByIdentityQueryResult,
  Maybe,
} from "../../schema/generated/schema";
import { namedPageView, genericTrack, track } from "../../utils/analytics";
import * as Banners from "../NotificationBanner/bannerRegistry";
import {
  mktgCardsPersonalizationFeature,
  getSplitIOConfig,
} from "../../utils/splitIO";
import {
  origins,
  enterpriseWorkatoApiDomain,
  enterpriseWorkatoApiKey,
  SENTRY_DSN,
} from "../../utils/constants";
import CarbonOffset from "../CarbonOffset";
import { PunchOutContext } from "../PunchOutContext";
import AnonymousQuoteStart from "../AnonymousQuoteStart";
import { addImportDependencies } from "../../utils/lazyLoad";
import QuoteWithoutCADPanel from "../QuoteWithoutCADPanel/QuoteWithoutCADPanel";
import {
  getDotNeutralData,
  getLowerCaseEmailDomain,
} from "../../utils/helpers";
import RecentOrders from "../RemoteComponents/RecentOrders/RecentOrders";
import { QuoteListComponent } from "../RemoteComponents/QuoteList/QuoteListComponent";
import AnnouncementBanner from "../AnnouncementBanner";
import TeamspaceBanner from "../TeamspaceBanner";
import { LazyTabView } from "../LazyTabView/LazyTabView";
import RecentQuotes from "../RemoteComponents/RecentQuotes/RecentQuotes";
import RecentTools from "../RemoteComponents/RecentTools/RecentTools";
import OrganizationSwitcher from "../OrganizationSwitcher/OrganizationSwitcher";
import LoftShadowProvider from "../../styles/LoftShadowProvider";
import PendingInvitesBanner from "../PendingInvitesBanner/PendingInvitesBanner";
import ScriptLoader from "../../utils/ScriptLoader";

declare global {
  interface Window {
    CustomerTopNav: any;
    repAttemptCounter: number;
  }
}

const ErrorState = () => (
  <div css={errorWrapper}>
    <div className="errorPanel">
      <div className="errorText">
        <h1>Unexpected Error</h1>
        <p> Please refresh the page to try again.</p>
      </div>
    </div>
  </div>
);

const App = () => {
  const { punchOut, punchoutSessionId } = useContext(PunchOutContext);

  useEffect(() => {
    namedPageView("Customer Home", "Instant Quote Funnel");
  }, []);
  const [componentLoaded, setComponentLoaded] = useState(false);
  const [showAccountReps, setShowAccountReps] = useState(false);
  const [activeTableTab, setActiveTableTab] = useState<number>(1); // 0 for Quotes, 1 for Orders
  const [shareError, setShareError] = useState(false);
  const isSSR = typeof window === "undefined";
  const { isStaff, isEUQuoter } = useContext(Auth);

  if (!isSSR) {
    addImportDependencies();
  }
  useEffect(() => {
    async function loadComponent() {
      const loader = new ScriptLoader(
        `${origins.home}/quoting/components/customer-top-nav/component.js`
      );
      const loadState: boolean = await loader.load();
      setComponentLoaded(loadState);
    }
    loadComponent();
  }, []);

  let TopNav: any;
  let Footer;
  let AccountReps;
  if (componentLoaded && window.CustomerTopNav) {
    TopNav = window.CustomerTopNav.Navigation;
    Footer = window.CustomerTopNav.NavigationFooter;
    AccountReps = window.CustomerTopNav.AccountReps || null;
  }

  const { loading, error, data } = useQuery<GetLoggedInUserQueryResult>(
    GetLoggedInUserQuery,
    {
      errorPolicy: "all",
    }
  );

  const loggedInUser = data?.me;

  const [segmentLoaded, setSegmentLoaded] = useState(false);
  const [usersOrgs, setUsersOrgs] = useState<
    GetUsersOrganizationsByIdentityQueryResult["getUsersOrganizationsByIdentity"]
  >([]);
  const maxRepAttempts = 120;
  const repAttemptInterval = 1000;

  const { data: getUserCarbonOffsetData } = useQuery(GetUserCarbonOffsetQuery, {
    variables: {
      userId: loggedInUser?.id || "",
    },
    skip: !loggedInUser?.id,
  });

  const [dotNeutralOffset, setDotNeutralOffset] = useState(0);

  if (SENTRY_DSN) {
    Sentry.setUser({
      id: loggedInUser?.id,
      email: loggedInUser?.emailAddress ?? "",
    });
    FullStory("setIdentity", {
      uid: loggedInUser?.id,
      properties: {
        displayName: `${loggedInUser?.firstName} ${loggedInUser?.lastName}`,
        email: loggedInUser?.emailAddress,
      },
    });
  }

  useEffect(() => {
    getDotNeutralData().then(responseData => {
      setDotNeutralOffset(responseData.offsets?.total_lbs);
    });
  }, []);

  useEffect(() => {
    window?.analytics?.ready(() => {
      setSegmentLoaded(true);
    });
  }, []);

  useEffect(() => {
    if (segmentLoaded && loggedInUser) {
      const { id, firstName, lastName, emailAddress } = loggedInUser;
      if (window.analytics) {
        window.analytics.identify(id, {
          name: `${firstName || ""} ${lastName || ""}`.trim(),
          email: emailAddress,
          punchoutSessionId,
          punchout: punchOut ? 1 : 0,
        });
      }
      const qm = window.QuantumMetricAPI;
      if (qm) {
        qm.sendEvent(328, 0, id);
        qm.sendEvent(284, 38, emailAddress);
      }
      if (punchOut) {
        genericTrack("PunchOut Dashboard Page Visited", {
          name: `${firstName || ""} ${lastName || ""}`.trim(),
          email: emailAddress,
          punchoutSessionId,
        });
      }
    }
  }, [loggedInUser, segmentLoaded, punchOut, punchoutSessionId]);

  useEffect(() => {
    window.repAttemptCounter = 0;
    const repIntervalId = window.setInterval(() => {
      if (showAccountReps || window.repAttemptCounter > maxRepAttempts) {
        window.clearInterval(repIntervalId);
        return;
      }
      if (document.querySelectorAll("#account-rep-wrapper img").length) {
        setShowAccountReps(true);
        window.clearInterval(repIntervalId);
      }
      window.repAttemptCounter += 1;
    }, repAttemptInterval);
  }, []);

  /* Recent Orders/Quotes section (Tabs) */
  const tabs = [
    {
      header: "Quotes",
      content: (
        <div css={tableContainer}>
          <RecentQuotes
            onError={err => console.error(err?.message)}
            setShareError={setShareError}
            isTeamView={false}
          />
        </div>
      ),
    },
    {
      header: "Orders",
      content: (
        <div css={tableContainer}>
          <RecentOrders
            onError={err => console.error(err?.message)}
            setShareError={setShareError}
            isTeamView={false}
          />
        </div>
      ),
    },
    {
      header: "Tools",
      content: (
        <div css={tableContainer}>
          <RecentTools onError={err => console.error(err?.message)} />
        </div>
      ),
    },
  ];

  const activeTabSubtitle = (activeValue: number) => {
    if (activeValue === 0) {
      return " Quotes";
    }
    if (activeValue === 1) {
      return " Orders";
    }
    return " Tools";
  };
  const tabsSection = (
    <>
      <Flex css={subtitle}>
        <Flex>
          {" "}
          Recent
          {activeTabSubtitle(activeTableTab)}
        </Flex>
        <Toast
          kind="danger"
          scale={theme.scale.small}
          showDuration={5000}
          isOpen={shareError}
          onClose={() => {
            setShareError(false);
          }}
          marginBottom={0}
        >
          Select one or more orders that you want to share.
        </Toast>
      </Flex>

      <TabContainer>
        <LazyTabView
          tabs={tabs}
          activeTab={activeTableTab}
          setActiveTabInParent={tab => {
            setActiveTableTab(tab);
          }}
        />
      </TabContainer>
    </>
  );

  // We need to hide the Split banners for all SpaceX punchout users, which
  // we can really only determine by punchOut === true and email domain.
  const hideSplitBanners = (isPunchOut: boolean, emailAddress: Maybe<string>) =>
    isPunchOut && getLowerCaseEmailDomain(emailAddress) === "spacex.com";

  if (error) {
    console.error(error.message);

    return (
      <div
        css={css`
          min-height: 100vh;
        `}
      >
        {componentLoaded && TopNav && (
          <LoftShadowProvider sticky>
            <TopNav
              appRootBaseUrl={origins.get}
              wwwBaseUrl={origins.home}
              user={data.me}
              isStaff={isStaff}
              isEUQuoter={isEUQuoter}
              isAuthLoading={loading}
              punchOut={punchOut}
              punchOutSessionId={punchoutSessionId}
            />
          </LoftShadowProvider>
        )}
        <div
          css={css`
            flex: 1 1 auto;
          `}
        >
          <ErrorState />
        </div>
        {componentLoaded && Footer && origins && (
          <LoftShadowProvider>
            <Footer wwwBaseUrl={origins.home} />
          </LoftShadowProvider>
        )}
      </div>
    );
  }

  if (loading) return null;

  return (
    <SplitFactory config={getSplitIOConfig(loggedInUser?.id || "anonymous")}>
      <div
        css={css`
          min-height: 100vh;
          display: flex;
          flex-direction: column;
        `}
      >
        <div css={topWrapper}>
          <AnnouncementBanner />
          {componentLoaded && TopNav && (
            <LoftShadowProvider>
              <TopNav
                appRootBaseUrl={origins.get}
                wwwBaseUrl={origins.home}
                user={data.me}
                isStaff={isStaff}
                isEUQuoter={isEUQuoter}
                isAuthLoading={loading}
                punchOut={punchOut}
                punchOutSessionId={punchoutSessionId}
              />
            </LoftShadowProvider>
          )}
          {loggedInUser && (
            <Flex
              width="100%"
              backgroundColor={theme.colors.white}
              borderBottomWidth={2}
              borderBottomColor={theme.colors.darkBlues[10]}
              borderBottomStyle="solid"
            >
              <Flex flexDirection="column" css={switcherNav}>
                <Text kind="page-title" css={dashboardTitle}>
                  Dashboard
                </Text>
                <OrganizationSwitcher
                  loggedInUserId={loggedInUser.id}
                  setUsersOrgs={setUsersOrgs}
                />
              </Flex>
            </Flex>
          )}
        </div>
        <div
          css={css`
            flex: 1 1 auto;
          `}
        >
          {loggedInUser ? (
            <>
              <div css={mainGrid}>
                <div css={leftColumn}>
                  <div
                    css={welcomeTextWrapper}
                    id="welcome-message"
                    className="pii-encrypt"
                  >
                    <Text kind="subpage-title" color={theme.colors.grays[0]}>
                      {loggedInUser.firstName
                        ? `Welcome back, ${loggedInUser.firstName}!`
                        : "Welcome Back!"}
                    </Text>
                  </div>
                  <>
                    <PendingInvitesBanner loggedInUser={loggedInUser} />
                    <QuoteWizardWithRequestQuote />
                    <div>
                      <div css={subtitle}>
                        <div>Pick Up Where You Left Off</div>
                        <div className="history-links">
                          <Link
                            href={`${origins.webapp}/quotes`}
                            onClick={() => {
                              track("View Recent Quotes");
                            }}
                          >
                            View All Quotes
                          </Link>
                        </div>
                      </div>
                      <QuoteListComponent
                        punchoutSessionId={punchoutSessionId}
                      />
                    </div>
                    {tabsSection}
                    {usersOrgs.length > 0 ? (
                      <QuoteWithoutCADPanel loggedInUser={loggedInUser} />
                    ) : (
                      <TeamspaceBanner />
                    )}
                    {hideSplitBanners(
                      punchOut,
                      loggedInUser?.emailAddress
                    ) ? null : (
                      <Banners.SplitIOConfigurable propsKey="pspSecondaryBannerProps" />
                    )}
                  </>
                </div>

                <div css={rightColumn}>
                  {punchOut && AccountReps && (
                    <div
                      css={lightPanel}
                      id="account-rep-wrapper"
                      style={showAccountReps ? {} : { display: "none" }}
                    >
                      <AccountReps
                        email={loggedInUser.emailAddress}
                        enterpriseApiDomain={enterpriseWorkatoApiDomain}
                        enterpriseApiKey={enterpriseWorkatoApiKey}
                      />
                    </div>
                  )}
                  {hideSplitBanners(
                    punchOut,
                    loggedInUser?.emailAddress
                  ) ? null : (
                    <>
                      <SplitTreatments
                        names={[mktgCardsPersonalizationFeature]}
                      >
                        {({ treatments, isReady }): JSX.Element | null => {
                          return isReady &&
                            treatments[mktgCardsPersonalizationFeature]
                              .treatment === "on" ? (
                            <div css={adsContainer}>
                              <PersonalizedMarketingCards
                                loggedInUser={loggedInUser}
                              />
                            </div>
                          ) : (
                            <div css={adsContainer}>
                              <MarketingCards
                                prismicClient={prismicClient}
                                loggedInUser={loggedInUser}
                              />
                            </div>
                          );
                        }}
                      </SplitTreatments>

                      <CarbonOffset
                        userCarbonOffset={
                          getUserCarbonOffsetData?.getUserCarbonOffset
                        }
                        dotNeutralOffset={dotNeutralOffset}
                      />
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            <AnonymousQuoteStart />
          )}
        </div>
        {componentLoaded && Footer && origins && (
          <LoftShadowProvider>
            <Footer wwwBaseUrl={origins.home} />
          </LoftShadowProvider>
        )}
      </div>
    </SplitFactory>
  );
};

export default App;
